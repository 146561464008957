@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i);

* {
	font-family: Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
	color: "#fff";
}

html {
	height: 100vh;
}

body {
	background: url("./assets/dashboard_bg.png") no-repeat;
	background-position: top;
	/* background-attachment: fixed; */
	background-size: cover;
	height: 100vh;
	margin: 0;
}

#main {
	display: flex;
}

#left-panel {
	flex: 1;
	position: relative;
}

#right-panel {
	flex: 3;
}

#site-title {
	font-weight: bold;
	font-size: 20px;
	line-height: 28px;
	width: 260px;
	text-align: center;
	color: #fff;
	opacity: 1;
	position: absolute;
	top: 20px;
	left: 50%;
	margin-left: -130px;
}

#top-left {
	height: 105.73px;
	margin-top: 81.5px;
	width: 100%;
	background: url("./assets/top_column_left_bg.png") no-repeat;
	background-position: left top;
	background-size: cover;
	padding-top: 16px;
}

#top-left .big-text {
	font-weight: normal;
	font-size: 55px;
	width: 100%;
	text-align: center;
	color: #fff;
	margin: 0;
}

.blue-text {
	font-weight: bold;
	font-size: 16px;
	text-align: center;
	color: #61bfeb;
	width: 100%;
}

#table {
	margin-left: 10px;
	margin-top: 26.4px;
}

.row,
.header {
	display: flex;
	padding-left: 10px;
}

.col1 {
	flex: 1;
}

.col3 {
	flex: 3;
}

.col6 {
	flex: 6;
}

.header .col {
	font-weight: normal;
	font-size: 20px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #67e2ff;
	line-height: 42px;
}

.row .col1 {
	font-weight: normal;
	font-style: italic;
	font-size: 30px;
	line-height: 28px;
	text-align: left;
	color: #67e1fe;
}

.row .col6 {
	font-weight: normal;
	font-size: 18px;
	line-height: 28px;
	text-align: left;
	color: #fff;
}

.row .col3 {
	padding-left: 15px;
	text-align: left;
	padding-top: 8px;
}

.header {
	padding-left: 16.6px;
	height: 42px;
	background: url("./assets/header_bg.png") no-repeat;
	background-size: cover;
	background-position: center;
}
.row {
	margin-left: 16.6px;
	margin-bottom: 5px;
	height: 41px;
	background: url("./assets/icons_SVG/table_site_column_bg.svg") no-repeat;
	background-size: cover;
	background-position: center;
	cursor: pointer;
}

.separator {
	width: 100%;
	height: 3.25px;
	background: linear-gradient(#75b931 0%, #ddc022 100%);
	border: 1px solid rgba(162, 209, 244, 0.04);
	margin-bottom: 8px;
}

.row .col {
	line-height: 41px;
}

.table-content {
	height: 374px;
	overflow: scroll;
	overflow-x: hidden;
}

#add-new-site {
	cursor: pointer;
	width: 100%;
	height: 37.51px;
	/* background: url("./assets/icons_SVG/button_add_new_site_text.svg") no-repeat;
	background-size: contain;
	background-position: left; */
	background: linear-gradient(#0c2553 0%, rgba(0, 0, 0, 0.38) 100%);
	margin-left: 17px;
}

#bottom-left {
	height: 200px;
	margin-left: 10px;
	margin-top: 20px;
	background: url("./assets/icons_SVG/panel_site_info_bg.svg") no-repeat;
	background-size: cover;
	background-position: center;
	padding-top: 15px;
	padding-left: 15.8px;
	/* padding-bottom: 20px; */
}

.site-title {
	font-weight: bold;
	font-size: 18px;
	line-height: 28px;
	text-align: left;
	color: #fff;
	padding: 0;
	margin: 0;
}
.blue-title {
	font-weight: normal;
	margin: 0;
	margin-top: 10px;
	font-size: 20px;
	line-height: 26px;
	text-align: left;
	color: #67e1fe;
}

#go-to-site {
	/* margin: 0 auto; */
	/* margin-top: 30px; */
}

#nav {
	display: flex;
	vertical-align: middle;
	height: 43px;
	position: absolute;
	right: 26px;
	top: 30px;
}

#nav a {
	flex: 1;
	height: 43px;
	width: 43px;
	margin-left: 10px;
	line-height: 43px;
}

#nav a img {
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
}

#pana-map {
	position: relative;
	margin-top: 82px;
	height: 850px;
}

::-webkit-scrollbar {
	width: 12px;
	height: 93px;
}
::-webkit-scrollbar-thumb {
	border-radius: 6px;
	background: #2e4a6c;
	box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.16);
}

.row-active {
	background: linear-gradient(#0b95fd 0%, #00a5f2 100%) !important;
}

.gm-fullscreen-control {
	bottom: 94px !important;
}

#reset-zoom {
	position: absolute;
	bottom: 125px;
	right: 10px;
	z-index: 200000;
	cursor: pointer;
	background: url("./assets/icons_SVG/reset.png") no-repeat;
	background-size: cover;
	background-position: center;
	width: 40px;
	height: 40px;
}
.info-window {
	background-color: transparent;
	line-height: 5px;
	font-weight: 500;
	font-size: 17px;
	text-align: center;
	color: #fcfcfc;
	text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
}

.gm-style .gm-style-iw-t {
	bottom: 70px !important;
}

.gm-style .gm-style-iw-c {
	background: #000;
	border: 1px solid #343434;
	overflow: hidden;
	padding: 10px;
	padding-top: 0px;
	border-radius: 0;
}

.gm-style .gm-style-iw-t::after {
	background: #000 !important;
}

.gm-style-iw-d {
	overflow: hidden !important;
	padding-right: 10px !important;
}

.gm-ui-hover-effect {
	display: none !important;
}
